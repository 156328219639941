<template>
  <v-container>
    <v-dialog v-model="dialog" persistent max-width="80%">
      <v-card>
        <v-card-text v-if="handbookDocument !== ''">
          <pdf
            v-for="i in getPages(handbookDocument)"
            :key="i"
            :src="handbookDocument"
            :page="i"
          />
        </v-card-text>
        <v-card-text v-else>
          <p class="font-weight-black mt-3">Nothing to Preview</p>
        </v-card-text>
        <v-divider />
        <v-card-actions class="handbookcardactions">
          <v-btn
            @click="$router.back()"
            dense
            class="mx-3 error lighten-4 error--text text--darken-4"
          >
            <v-icon class="mx-2">mdi-arrow-left</v-icon>
            Back
          </v-btn>

          <v-spacer />

          <v-btn
            class="mx-3"
            @click="downloadLink(handbookDocument)"
            dense
            color="primary"
          >
            Download Handbook
          </v-btn>

          <v-form
            v-model="isValid"
            ref="submitForm"
            v-if="!checkIfHandbookIsRead(handbook.code)"
          >
            <div class="d-flex justify-space-between">
              <v-checkbox
                class="mx-5"
                color="primary"
                v-model="formData.hasRead"
                label="I have Read the Employee Document"
                :rules="rules.required"
              ></v-checkbox>
              <div>
                <v-btn
                  class="handbook--btn mt-3 mr-5"
                  color="primary"
                  @click.prevent="submit"
                >
                  Submit
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style>
.handbook--btn > .v-btn__content {
  font-size: 16px;
  text-transform: none;
}
.handbookcardactions {
  position: sticky;
  bottom: 0;
  z-index: 999;
  background-color: #fff;
}
</style>
<script>
import pdf from "vue-pdf";
import FileMixin from "@kinetics254/cassandra-base/mixins/FileMixin.js";

export default {
  name: "HandBookCard",
  mixins: [FileMixin],
  components: { pdf },
  data: function () {
    return {
      numPages: undefined,
      agree: false,
      isValid: false,
      dialog: true,
      formData: {
        readHandbook: null,
        employeeNo: "",
        handbookCode: "",
        hasRead: false,
      },
    };
  },

  computed: {
    rules() {
      return {
        required: [(v) => !!v || "This field is required"],
      };
    },
    HandbookLogs() {
      return this.$store.getters["handbook/allReadhandbooks"];
    },
    employeeData: function () {
      const employee = this.$store.getters["profile/employee"][0];
      return employee || {};
    },
    handbook() {
      const handbooks = this.$store.getters["handbook/handbook"];
      return handbooks
        ? handbooks.filter((d) => d.code === atob(this.$route.params.code))[0]
        : {};
    },
    handbookDocument() {
      return this.handbook
        ? process.env.VUE_APP_FILE_API + this.handbook.handbook
        : "";
    },
  },
  watch: {
    employeeData() {
      this.formData.readHandbook = this.employeeData.readHandbook;
    },
  },
  methods: {
    submit() {
      if (!this.isValid) {
        this.$refs.submitForm.validate();
      } else {
        const data = {
          handbookCode: this.handbook.code,
          employeeNo: this.employeeData.number,
          hasRead: this.formData.hasRead,
        };
        this.$store.dispatch("handbook/readHandbook", { ...data });
      }
    },
    getPages: function (document) {
      if (document !== "") {
        document = pdf.createLoadingTask(document);
        document.promise.then((pdf) => {
          this.numPages = pdf._pdfInfo.numPages;
        });
        return this.numPages;
      }
    },
    checkIfHandbookIsRead(val) {
      return this.HandbookLogs.find((d) => d.handbookCode === val)
        ? this.HandbookLogs.find((d) => d.handbookCode === val).hasRead
        : false;
    },
  },
  mounted() {
    this.$store.getters["handbook/allReadhandbooks"];
  },
};
</script>
